import classNames from 'classnames';
import Link from 'next/link';
import type { FunctionComponent } from 'react';
import { Box } from '../../components/box';
import { Details } from '../../components/details';
import { Container } from '../../components/layout/grid';
import { List } from '../../components/list';
import { Markdown } from '../../components/markdown';
import { MarkdownRenderer } from '../../components/markdown-renderer';
import { Paragraph } from '../../components/paragraph';
import { ArrowIcon } from '../../components/svgs';
import { Text } from '../../components/text';
import { useSectionOfFAQs } from '../../graphql/hooks/section-of-faqs';
import { EMPTY_COLLECTION, EMPTY_SECTION } from '../../graphql/placeholders';
import type { FaqSectionFragment } from '../../graphql/queries/GetSectionOfFAQs.graphql';
import { titleToAnchor } from '../../lib/utils/faq';
import type { EntryMeta } from '../../types/sys';
import { RichText } from '../rich-text';
import type { CustomRenderers } from '../rich-text/render';
import styles from './section-of-faqs.module.scss';
export const SectionOfFAQs: FunctionComponent<FaqSectionFragment> = ({
  sys,
  type,
  heading,
  moreLink,
  ...restOfProps
}) => {
  if (restOfProps?.faqs) {
    return <SectionOfFAQResolved sys={sys} type={type} heading={heading} moreLink={moreLink} {...restOfProps} />;
  }
  return <SelfFetchingSectionOfFAQs sys={sys} type={type} data-sentry-element="SelfFetchingSectionOfFAQs" data-sentry-component="SectionOfFAQs" data-sentry-source-file="section-of-faqs.tsx" />;
};
const SelfFetchingSectionOfFAQs: FunctionComponent<EntryMeta> = ({
  sys
}) => {
  const {
    section
  } = useSectionOfFAQs(sys.id);
  return <SectionOfFAQResolved {...section} data-sentry-element="SectionOfFAQResolved" data-sentry-component="SelfFetchingSectionOfFAQs" data-sentry-source-file="section-of-faqs.tsx" />;
};

/**
 * Renders a section of FAQs
 */
const SectionOfFAQResolved: FunctionComponent<FaqSectionFragment> = ({
  heading,
  moreLink,
  faqs: {
    items
  } = EMPTY_COLLECTION,
  section: {
    title,
    subtitle
  } = EMPTY_SECTION
}) => {
  const id = title ? titleToAnchor(title) : undefined;
  return <div id={id} data-sentry-component="SectionOfFAQResolved" data-sentry-source-file="section-of-faqs.tsx">
            <Container className={styles.section} data-sentry-element="Container" data-sentry-source-file="section-of-faqs.tsx">
                <Box pad={[]} data-sentry-element="Box" data-sentry-source-file="section-of-faqs.tsx">
                    {(heading || title) && <div className={styles.header}>
                            <Markdown markdown={heading ?? title} tag='h2' typeStyle={{
            _: 'heading-sm',
            md: 'heading-xl'
          }} />
                        </div>}
                    {subtitle && <Paragraph>{subtitle}</Paragraph>}

                    {items.map(({
          anchor,
          question,
          answer,
          content
        }, i: number) => <Details key={question ?? i} summary={question} anchor={anchor}>
                                {content?.content ? <RichText source={content.content} renderers={renderers} /> : <MarkdownRenderer source={answer ?? ''} renderers={renderers} />}
                            </Details>)}
                    {moreLink?.path && <Link href={moreLink.path}>
                            <div className={classNames(styles['external-link'])}>
                                <Text typeStyle='body-lg' tag='div' bold color='currentColor'>
                                    {moreLink.title}
                                </Text>
                                <ArrowIcon />
                            </div>
                        </Link>}
                </Box>
            </Container>
        </div>;
};
const renderers: CustomRenderers = {
  listItem: (_, children) => <List.Item className={styles.item}>{children}</List.Item>,
  paragraph: (_, children) => <Paragraph className={styles.paragraph}>{children}</Paragraph>,
  hr: () => <hr className={styles.hr} />
};
export default SectionOfFAQs;